import styled from "styled-components";
import {
  Rem,
  BP,
  Colors,
} from "../../../commons/Theme";
import { StyledBeeCta, StyledWrapperButton } from "../BeeWho/style.jsx";

export const PayCardsWrapper = styled.div`
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 93rem;
  @media (${BP.tablet}) {
    max-width:1248px;
    margin: 0 auto;
    gap: 3rem;
    padding: ${Rem(100)} 2rem;
  }
`;

export const StyledPayCardsTitle = styled.h1`
  text-align: left;
  font-size: ${Rem(32)};
  padding: 0 1.25rem;
  width: 100%;
  @media (${BP.tablet}) {
    text-align: center;
    margin: 0 auto;
    font-size: ${Rem(40)};
    padding: 0;
    padding-bottom: 2rem;
    width: unset;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding: 2rem 1.25rem;

  @media (${BP.tablet}) {
    padding: 0;
    flex-direction: row;
    gap: 2rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 735px;
  border: 2px solid ${Colors.yellow};
  border-radius: 10px;
  width: 100%;
  padding: 2rem;
  @media (${BP.tablet}) {
    width: 50%;
    padding: 3rem;
    min-height: 850px;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 4rem; */
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.p`
  font-size: ${Rem(16)};
  font-weight: 600; 
  text-align: left;
  line-height: 2rem;
  color: ${Colors.yellow};
`;

export const StyledTitleHeader = styled.h3`
  font-size: ${Rem(18)};
  text-align: left;
  @media (${BP.tablet}) {
    font-size: ${Rem(32)};
  }
`;

export const StyledPrice = styled.p`
  font-size: ${Rem(32)};
  line-height: 1.5rem;
  color: ${Colors.yellow};
  span {
    font-size: ${Rem(12)};
  } 
  @media (${BP.tablet}) {
    line-height: 2rem;
    font-size: ${Rem(52)};
    span {
      font-size: ${Rem(22)};
    }
  }
`;

export const StyledParagraph = styled.p`
  font-size: ${Rem(12)};
  font-weight: 300;
  color: ${Colors.black};
  padding-bottom: 2rem;
  @media (${BP.tablet}) {
    font-size: ${Rem(20)};
    min-height: 155px;
  }
`;

export const WrapperFeatures = styled.ul`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const StyledParagraphLi = styled.li`
  font-size: ${Rem(12)};
  font-weight: 300;
  color: ${Colors.black};
  opacity: ${({ isFeatured }) => isFeatured === true ? 1 : 0.4};
  @media (${BP.tablet}) {
    font-size: ${Rem(20)};
  }
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (${BP.tablet}) {
    gap: 2rem;
  }
`;

export const StyledImageCheck = styled.img`
  width: 21px;
  height: 21px;
  object-fit: cover;
  @media (${BP.tablet}) {
    width: 32px;
    height: 32px;
  }
`;

export const StyledDisclaimer = styled.p`
  font-size: ${Rem(10)};
  font-weight: 300; 
  text-align: center;
  line-height: 2rem;
  color: ${Colors.black};
  @media (${BP.tablet}) {
    font-size: ${Rem(16)};
  }
`;

export const WrapperButton = styled(StyledWrapperButton)`
  display: flex;
  justify-content: center;
`;

export const StyledCta = styled(StyledBeeCta)`
  @media (${BP.tablet}) {
    padding: ${Rem(0)} !important;
    a {
      padding: ${Rem(25)} ${Rem(100)};
    }
  }
`;
